import React, { useState } from "react";
import { Stepper, Step } from "react-form-stepper";
import DetailsPay from "../common/pay/DetailsPay";
import MakePayment from "../common/pay/MakePayment";
import SelectPaymentGateway from "../common/pay/SelectPaymentGateway";
import Banner from "../components/banner";
import Index from "../layout/Index";

const PlaceOrder = () => {
  const INITIAL_STATE = {
    step: 1,
    monto: "",
    nombre: "",
    correo: "",
    contrato: "",
    pasarela: "",
  };

  const [goSteps, setGoSteps] = useState(0);
  const [form_pay, setform_pay] = useState(INITIAL_STATE);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setform_pay((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Index>
      <Banner text="Pagos" />
      <Stepper
        activeStep={goSteps}
        styleConfig={{
          activeBgColor: "#003893",
          activeTextColor: "#fff",
          inactiveBgColor: "#e9e7e7",
          inactiveTextColor: "#003893",
          completedBgColor: "#ffcf04",
          completedTextColor: "#fff",
          size: "3em",
        }}
        className={"stepper"}
        stepClassName={"stepper__step"}
      >
        <Step onClick={() => setGoSteps(0)} label="Detalles de pago" />
        <Step onClick={() => setGoSteps(1)} label="Pasarela de pago" />
        <Step onClick={() => setGoSteps(2)} label="Realizar pago" />
      </Stepper>
      {goSteps === 0 && (
        <div>
          <DetailsPay
            handleChange={handleChange}
            form_pay={form_pay}
            setform_pay={setform_pay}
            setGoSteps={setGoSteps}
          />
        </div>
      )}
      {goSteps === 1 && (
        <div>
          <SelectPaymentGateway
            setform_pay={setform_pay}
            form_pay={form_pay}
            setGoSteps={setGoSteps}
          />
          <button className="btn-steps mt-5" onClick={() => setGoSteps(0)}>
            Anterior
          </button>
        </div>
      )}
      {goSteps === 2 && (
        <div>
          <MakePayment
            setform_pay={setform_pay}
            form_pay={form_pay}
            setGoSteps={setGoSteps}
          />
          <button className="btn-steps mt-5" onClick={() => setGoSteps(1)}>
            Anterior
          </button>
        </div>
      )}
    </Index>
  );
};

export default PlaceOrder;
