import React from 'react'
import Navbar from '../components/Navbar'
import Menu from './menu'

const Index = ( {children} ) => {
  return (
    <div className='row' style={{ margin: 0}}>
      <Navbar />
      <Menu />
      <div className='col-sm-12 col-md-10 col-lg-10 col-xl-10 mb-5'>
        {children}
      </div>
    </div>  
  )
}

export default Index