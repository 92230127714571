import React, { useEffect } from "react";
import Footer from "../components/footer";
import ReactGA from "react-ga";

const TRACKING_ID = "G-BJ1KD1C6WZ";

const Main = ({ children }) => {
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
  }, []);

  return (
    <div className="">
      {children}
      <Footer />
    </div>
  );
};

export default Main;
