import React from 'react'

const Starts = ( {score}) => {
  return (
    <div className="rating">
      {
        [...new Array(score)].map( (start, index) =>{
          return index < score ? 
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    // width="64"
                    // height="64"
                    fill="#000"
                    version="1.1"
                    viewBox="0 0 504.32 504.32"
                    xmlSpace="preserve"
                    key={index}
                    className='start_component'
                  >
                    <g>
                      <g transform="translate(1 1)">
                        <defs>
                          <filter
                            id="Adobe_OpacityMaskFilter"
                            // width="493.815"
                            // height="494.933"
                            x="4.629"
                            y="4.12"
                            filterUnits="userSpaceOnUse"
                          >
                            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"></feColorMatrix>
                          </filter>
                        </defs>
                        <mask
                          id="mask-2_1_"
                          // width="493.815"
                          // height="494.933"
                          x="4.629"
                          y="4.12"
                          maskUnits="userSpaceOnUse"
                        >
                          <g filter="url(#Adobe_OpacityMaskFilter)">
                            <path
                              fill="#FFF"
                              d="M-3.611 490.52L491.323 490.52 491.323 -4.413 -3.611 -4.413z"
                            ></path>
                          </g>
                        </mask>
                        <path
                          fill="#ffcf04"
                          d="M252.39 413.72l-115.2 78.507c-5.12 4.267-11.947 6.827-18.773 6.827-5.973 0-11.947-1.707-17.067-5.12-11.093-7.68-15.36-21.333-11.093-33.28l45.227-143.36L17.723 229.4c-11.093-7.68-15.36-20.48-11.947-33.28 3.413-12.8 15.36-21.333 28.16-21.333h144.213L223.376 24.6c4.267-11.947 15.36-20.48 28.16-20.48s24.747 8.533 28.16 21.333l44.373 149.333h145.067c12.8 0 24.747 8.533 28.16 21.333 3.413 12.8-.853 25.6-11.947 33.28l-117.76 87.893 45.227 143.36c4.267 12.8-.853 26.453-11.093 33.28-5.12 3.413-11.093 5.12-17.067 5.12-6.827 0-12.8-2.56-18.773-6.827L252.39 413.72z"
                          mask="url(#mask-2_1_)"
                        ></path>
                      </g>
                      <path
                        fill="#ffcf04"
                        d="M387.363 504.32c-7.68 0-15.36-2.56-21.333-7.68l-140.8-95.573c-1.707-1.707-2.56-4.267-.853-5.973 1.707-1.707 4.267-2.56 5.973-.853l140.8 96.427c9.387 6.827 21.333 7.68 30.72.853s12.8-17.92 9.387-29.013l-45.227-143.36c-.853-1.707 0-3.413 1.707-5.12l117.76-87.893c9.387-5.973 13.653-17.92 10.24-29.013-3.413-11.093-12.8-17.92-24.747-17.92H325.923c-1.707 0-3.413-.853-4.267-3.413L277.283 26.453c-3.413-10.24-12.8-17.92-23.893-17.92s-21.333 6.827-24.747 17.92L183.416 176.64c-.853 1.707-2.56 3.413-4.267 3.413H34.936c-11.093 0-21.333 7.68-24.747 17.92-3.413 11.093.853 22.187 10.24 28.16l117.76 87.893c1.707.853 1.707 2.56 1.707 5.12L94.67 462.507c-3.413 11.093.853 22.187 9.387 29.013 9.387 5.973 21.333 5.973 29.867-.853l85.333-58.88c1.707-1.707 4.267-.853 5.973.853 1.707 1.707.853 4.267-.853 5.973l-85.333 58.027c-11.093 8.533-28.16 9.387-40.107.853-12.8-8.533-17.92-23.893-12.8-38.4l44.373-139.947-115.2-86.186c-11.947-8.533-17.92-23.893-13.653-38.4s17.067-23.893 32.427-23.893h140.8l44.373-146.773C223.523 9.386 237.176 0 251.683 0c15.36 0 28.16 9.387 32.427 23.893l43.52 146.773h142.507c15.36 0 28.16 9.387 32.427 23.893 4.267 14.507-.853 29.867-13.653 38.4l-115.2 86.187 44.373 139.947c4.267 14.507-.853 29.867-12.8 38.4-4.268 5.12-11.094 6.827-17.921 6.827z"
                      ></path>
                    </g>
                  </svg>
                  : '' 
        })
      }
    </div>
  )
}

export default Starts