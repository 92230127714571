import React from 'react';
import { NavLink } from 'react-router-dom';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  890: { items: 3 },
  1024: { items: 4 },
};

const FeaturedCoupons = ( {cupon}) => {

  const items = [
    cupon?.map( (cupon, index) =>(
      <div className='item' data-value={index+1} key={cupon.id}>
        <NavLink to={`/cupon/${cupon.id}`}>
          <div className=" cardCupon cardOfertas">
            <div className='row'>
              <div className='col-lg-12 col-md-12 col-sm-12'>
                <div className="example2__ribbon example2__ribbon--tr">
                  <span className="example2__title" >Ver cupón</span>
                </div>
                <img src={cupon.img} className="card-img-top" alt="..."/>
              </div>
              <div className='col-lg-12 col-md-12 col-sm-12'>
                <div className="card-body ">
                  <p className="text-center">{cupon.titulo}</p>
                </div>
              </div>
            </div>
          </div>
        </NavLink> 
      </div>
    ))
  ];

  return (
    <AliceCarousel
      mouseTracking
      controlsStrategy="alternate"
      autoPlay={true}
      autoPlayInterval={1500}
      animationType="fadeout" 
      animationDuration={800}
      disableButtonsControls
      items={ items[0] }
      infinite
      responsive={responsive}
      // onSlideChange={ (e) => console.log(e.item)}
      // onSlideChanged={ (e) => console.log(e.slide)}
    />
  )
}

export default FeaturedCoupons