import React from "react";

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer
      className="bg-azul text-center text-lg-start p-1 fixed-bottom"
      style={{ padding: 0, position: "relative" }}
    >
      <div className="container text-center text-md-start mt-5">
        <div className="row">
          <div className="col-sm-6 col-md-3 col-lg-3 col-xl-3 mx-auto mb-4">
            <section className="centrar">
              <img src="/images/logo.png" alt="Aliados" height={100} />
            </section>
          </div>
        </div>
        <div className="text-center p-4">
          <p>
            <a
              className="text-reset fw-bold"
              href="https://grupoalianzacolombia.com/"
              rel="noopener noreferrer"
            >
              © {year} Copyright: Todos los derechos reservados
            </a>
          </p>
        </div>
        <div className="lines">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
