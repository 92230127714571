import React, { useEffect, useState } from "react";
import Banner from "../components/banner";
import confetti from "canvas-confetti";
import ReactGA from "react-ga";
import axios from "axios";
import Index from "../layout/Index";
import { useNavigate } from "react-router-dom";
import { getInfo } from "../service/getInfo";
import Promotions from "../components/promotions";
import UserMora from "../common/UserMora";
import useTimeOut from "../common/hook/useTimeOut";
import ChangePassword from "../components/changePassword";
import SecondLoad from "../common/SecondLoad";

function capitalizar(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

const Booking = () => {
  const [urlPrice, setUrlPrice] = useState(null);
  const [allData, setAllData] = useState(false);
  const [stateMora, setStateMora] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  // get info user
  const getInfoUser = async () => {
    try {
      const response = await getInfo();
      if (response.data.msg === "Token invalid") {
        localStorage.clear();
        return navigate("/");
      } else {
        if (Object.keys(response.data.data.info.afiliado1).length > 0) {
          setUser(response.data.data.info);
        } else {
          navigate("/usuario");
        }
      }
    } catch (error) {
      if (error.response.status === 401) localStorage.clear();
    }
  };

  const getPrice = async () => {
    const url = `https://aliados-production.up.railway.app/api/users/getprice`;
    try {
      const response = await axios.get(url, {
        header: "Content-Type Aplication/json",
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      });
      if (response.msg === "Token invalid") {
        localStorage.clear();
        return navigate("/");
      } else {
        setUrlPrice(response.data);
      }
    } catch (error) {
      if (error.response.status === 401) localStorage.clear();
    }
  };

  const ValidateAllInfo = () => {
    if (
      user.afiliado1?.email?.length < 0 ||
      user.afiliado1?.titular?.length < 0 ||
      user.contracto?.ciudad?.length < 0 ||
      user.contracto?.contrato?.length < 0
    ) {
      setAllData(true);
    }
  };

  const crossLogin = async () => {
    try {
      const response = await getPrice();
      if (response.msg === "Token invalid") {
        localStorage.clear();
        return navigate("/");
      } else {
        setUrlPrice(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getInfoUser();
    setStateMora(localStorage.getItem("state_mora"));
    ReactGA.pageview(window.location.pathname); //Google Analytics
    ReactGA.event({
      category: "Pagina de reserva",
      action: "Ingreso pagina de reserva",
      label: "Notificación ingreso pagina de reserva ",
    });

    crossLogin();
    confetti({
      spread: 660,
      ticks: 50,
      gravity: 0,
      decay: 0.94,
      startVelocity: 30,
      shapes: ["star"],
      colors: ["FFE400", "FFBD00", "E89400", "FFCA6C", "FDFFB8"],
      particleCount: 50,
      scalar: 1.2,
    });
  }, []);

  useTimeOut(() => crossLogin(), 1000);
  useTimeOut(() => ValidateAllInfo(), 1200);

  return (
    <>
      {user ? (
        <>
          {user.validaciones.chpwd === "1" ? (
            <ChangePassword />
          ) : (
            <Index>
              <Banner text={capitalizar(user?.afiliado1?.titular) || ""} />
              {user.validaciones.mora === "1" ? (
                <UserMora text={user.validaciones.mmora.slice(38)} />
              ) : (
                <>
                  <div className="">
                    {urlPrice != null ? (
                      <>
                        {!allData ? (
                          <div className=" row centrar">
                            <div className="col-sm-12 col-md-12 col-lg-7 col-xl-7">
                              {stateMora ? (
                                <UserMora
                                  text={user.validaciones.mmora.slice(38)}
                                />
                              ) : urlPrice ? (
                                <Promotions urlPrice={urlPrice} />
                              ) : (
                                <SecondLoad />
                              )}
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                {urlPrice ? (
                                  <a
                                    href={stateMora ? "#" : urlPrice}
                                    target={stateMora ? "" : "_blank"}
                                    rel="noopener noreferrer"
                                    className={`${
                                      stateMora ? "booking-disabled" : ""
                                    }`}
                                    data-bs-toggle={stateMora ? "modal" : ""}
                                    data-bs-target={
                                      stateMora ? "#exampleModal" : ""
                                    }
                                  >
                                    <div className="cardHotel">
                                      <div className="centrar m-1">
                                        <img
                                          src="/images/logo_aliados.png"
                                          alt="Grupo alianza colombia"
                                          width={160}
                                        />
                                      </div>
                                      <h6 className="text-center text-italic">
                                        Hoteles con tarifas especiales
                                      </h6>
                                      <button className="btn-reservar mt-2">
                                        Reservar
                                      </button>
                                    </div>
                                  </a>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="toast fade show" role="alert">
                            <div className="toast-header">
                              <svg
                                className="bd-placeholder-img rounded me-2"
                                width="400"
                                height="20"
                                xmlns="http://www.w3.org/2000/svg"
                                aria-hidden="true"
                                preserveAspectRatio="xMidYMid slice"
                                focusable="false"
                              >
                                <rect
                                  width="100%"
                                  height="100%"
                                  fill="#007aff"
                                ></rect>
                              </svg>
                              <strong className="me-auto">
                                Información importante
                              </strong>
                            </div>
                            <div className="toast-body">
                              Para realizar una reserva a través de nuestro
                              portal debes tener actualizados tus datos, si
                              deseas realizar actualización de tus datos
                              comunicate a la línea{" "}
                              <a
                                className="text-primary"
                                href="tel:+576015520100"
                              >
                                {" "}
                                (601)5520100
                              </a>
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <SecondLoad />
                    )}
                  </div>

                  <div
                    className="modal fade"
                    id="exampleModal"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog">
                      <div
                        className="modal-content"
                        style={{ borderRadius: "1rem" }}
                      >
                        <div className="card-mora" style={{ width: "100%" }}>
                          <h3 className="text-center">
                            {" "}
                            Tu plan vacacional presenta mora{" "}
                            {user?.validaciones?.mmora?.slice(38)}{" "}
                          </h3>
                          <a
                            target="_blank"
                            className="centrar text-white m-3"
                            href="https://wa.link/ar2rk4"
                            rel="noopener noreferrer"
                            style={{ textDecoration: "underline" }}
                          >
                            <img
                              src="/images/icono-de-whatsapp.png"
                              alt="img_WhatsApp"
                              style={{ width: "20px" }}
                            />{" "}
                            <p>Si deseas contactar tu agente de cuenta</p>
                          </a>
                          <h4 className="centrar">Medios de pago</h4>
                          <div className="modal-footer centrar">
                            <a
                              href="https://grupoalianzacolombia.com/mercadopago/datos-de-pago.php"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="col-sm-12 col-md-12 col-lg-12 col-xl-12 btn-mercado"
                              style={{ border: "none" }}
                            >
                              <span>
                                <img
                                  src="/images/Mercado-Pago.png"
                                  alt="img mercado de pago"
                                  width={48}
                                  style={{ marginRight: "10px" }}
                                />
                                Mercado pago
                              </span>
                            </a>
                            <a
                              href="https://grupoalianzacolombia.com/payu/datos-de-pago.php"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="col-sm-12 col-md-12 col-lg-12 col-xl-12 btn-payU"
                              style={{ border: "none" }}
                            >
                              <span>
                                <img
                                  src="/images/payu-logo.svg"
                                  alt="img payU"
                                  width={48}
                                />
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </Index>
          )}
        </>
      ) : (
        <Index>
          <SecondLoad />
        </Index>
      )}
    </>
  );
};

export default Booking;
