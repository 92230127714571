import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import baseUrl from "../utils/baseUrl";
import ReactGA from "react-ga";
import Index from "../layout/Index";
import SecondLoad from "../common/SecondLoad";

const Bidding = () => {
  const [ofertas, setOfertas] = useState(null);
  const [pagination, setPagination] = useState(0);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const GetOfertas = async () => {
    try {
      const url = `${baseUrl}/api/ofertas`;
      const response = await axios.get(url, {
        header: "Content-Type Aplication/json",
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      });
      if (response.data.msg === "Token invalid") {
        localStorage.clear();
        navigate("/");
      } else {
        setOfertas(response.data);
      }
    } catch (error) {
      if (error.response.status === 401) localStorage.clear();
    }
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname); //Google Analytics

    ReactGA.event({
      category: "Pagina de ofertas",
      action: "Ingreso a la pagina de ofertas ",
      label: "Notificación Ingreso a la pagina de ofertas",
    });
    GetOfertas();
  }, []);

  const dolar = (price) => {
    const price1 = price.split(" ");
    return price1[0];
  };

  const filterBiddings = () => {
    if (search.length === 0) return ofertas.slice(pagination, pagination + 6);
    // Busqueda activa
    const filtered = ofertas.filter(
      (oferta) =>
        oferta.titulo.toLowerCase().includes(search.toLowerCase()) ||
        oferta.descripcion.toLowerCase().includes(search.toLowerCase()) ||
        oferta.precio_descuento.toLowerCase().includes(search.toLowerCase()) ||
        oferta.precio_regular.toLowerCase().includes(search.toLowerCase()) ||
        oferta.tyc.toLowerCase().includes(search.toLowerCase()) ||
        oferta.expira.toLowerCase().includes(search.toLowerCase())
    );
    return filtered.slice(pagination, pagination + 6);
  };

  const next = () => {
    if (pagination + 6 < ofertas.length) setPagination(pagination + 6);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const back = () => {
    if (pagination > 0) setPagination(pagination - 6);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const onSearchChange = ({ target }) => {
    setPagination(0);
    setSearch(target.value);
  };

  return (
    <Index>
      <div className="container">
        <div className="row">
          {ofertas ? (
            <>
              <img
                src="/images/priceAlianzaPlus.png"
                alt="img_ofertas"
                className="img-ofertas"
              />
              <div className="row">
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <nav aria-label="centrar breadcrumb">
                    <ol className="breadcrumb text-center">
                      <li className="breadcrumb-item mt-1">
                        <NavLink className="" to={`/alianza-plus`}>
                          Alianza plus
                        </NavLink>
                      </li>
                      <li
                        className="breadcrumb-item active mt-1"
                        aria-current="page"
                      >
                        Ofertas
                      </li>
                    </ol>
                  </nav>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <div className="form">
                    <label>
                      <input
                        className="input"
                        type="text"
                        required=""
                        placeholder="Buscar..."
                        id="search"
                        value={search}
                        onChange={onSearchChange}
                      />
                      <div className="fancy-bg"></div>
                      <div className="search">
                        <svg
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          className="r-14j79pv r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-4wgw6l r-f727ji r-bnwqim r-1plcrui r-lrvibr"
                        >
                          <g>
                            <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
                          </g>
                        </svg>
                      </div>
                      <button
                        className="close-btn"
                        type="reset"
                        onClick={() => setSearch("")}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"></path>
                        </svg>
                      </button>
                    </label>
                  </div>
                </div>
              </div>
              {filterBiddings()?.map((oferta) => (
                <div className="col-lg-6  col-md-6 col-sm-6 " key={oferta.id}>
                  <NavLink to={`/oferta/${oferta.id}`}>
                    <div className="card cardOferta cardOfertas ">
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6">
                          <div className="example__ribbon example__ribbon--tr">
                            <span className="example__title">Ver oferta</span>
                          </div>
                          <img
                            src={oferta.portada}
                            className="card-img-top"
                            alt="..."
                          />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6">
                          <div className="traslucido">
                            <div className="card-body mt-5">
                              <h6 className="">{oferta.titulo}</h6>
                              <p className="text-dark">
                                Expira {oferta.expira}
                              </p>

                              <div className="row">
                                <div className="col-sm">
                                  <h6>Precio Regular:</h6>
                                  <del className="price-tag2">
                                    {oferta.precio_regular.includes("Dolares")
                                      ? dolar(oferta.precio_regular)
                                      : oferta.precio_regular}
                                  </del>
                                </div>
                                <div className="col-sm">
                                  <h6>Precio Afiliado:</h6>
                                  <p className="price-tag">
                                    {oferta.precio_descuento.includes("Dolares")
                                      ? dolar(oferta.precio_descuento)
                                      : oferta.precio_descuento}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                </div>
              ))}
              <div className="row centrar">
                <div className="col-lg-3 col-md-3 col-sm-12">
                  <button
                    disabled={pagination > 0 ? false : true}
                    onClick={back}
                    className="mt-4 button-back learn-more"
                  >
                    <span className="circle" aria-hidden="true">
                      <span className="icon arrow"></span>
                    </span>
                    <span className="button-back-text">Anterior</span>
                  </button>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-12">
                  <button
                    disabled={pagination + 6 < ofertas.length ? false : true}
                    onClick={next}
                    className="mt-4 button learn-more"
                  >
                    <span className="circle" aria-hidden="true">
                      <span className="icon arrow"></span>
                    </span>
                    <span className="button-text">Siguiente</span>
                  </button>
                </div>
              </div>
            </>
          ) : (
            <SecondLoad />
          )}
        </div>
      </div>
    </Index>
  );
};

export default Bidding;
