import React from "react";
import { NavLink, useLocation } from "react-router-dom";

const Navbar = () => {
  const location = useLocation();
  const logOut = () => {
    localStorage.clear();
  };
  const user = localStorage.getItem("token");
  return (
    <nav
      className={
        location.pathname === "/"
          ? "navbar sticky-top navbar-expand-lg "
          : "navbar sticky-top navbar-expand-lg bg-light color-nav"
      }
    >
      <div className="container-fluid">
        <NavLink to="/">
          <img
            src="/images/logo_aliados.png"
            alt="Grupoa Alianza Colombia"
            width={150}
          />
        </NavLink>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarTogglerDemo02"
          aria-controls="navbarTogglerDemo02"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        {user != null ? (
          <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink to="/dashboard">
                  <button
                    className={`${
                      location.pathname === "/dashboard"
                        ? "btn-menu-active"
                        : "btn-menu"
                    }`}
                  >
                    <img
                      src="/icons/Membresia.svg"
                      alt="image_membresia"
                      height={30}
                    />
                    Membresía
                  </button>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/reserva">
                  <button
                    className={`${
                      location.pathname === "/reserva"
                        ? "btn-menu-active"
                        : "btn-menu"
                    }`}
                  >
                    <img
                      src="/icons/Reserva.svg"
                      alt="image_reservar"
                      height={30}
                    />
                    Reservar
                  </button>
                </NavLink>
              </li>
              <li className="nav-item">
                {location.pathname === "/alianza-plus" ||
                location.pathname === "/cupones" ||
                location.pathname === "/ofertas" ||
                location.pathname === "/redenciones" ? (
                  <a
                    href="https://www.assistcard.com/co/b2c/grupo-alianza-colombia"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button
                      className={`${
                        location.pathname === "/redenciones"
                          ? "submenu"
                          : "btn-menu submenu"
                      }`}
                    >
                      <img
                        src="/icons/right.svg"
                        alt="icon_redentions"
                        height={30}
                      />
                      <img
                        src="/icons/assistCard.png"
                        alt="icon_redentions"
                        style={{
                          height: "24px",
                          width: "24%",
                          objectFit: "cover",
                        }}
                      />
                    </button>
                  </a>
                ) : (
                  ""
                )}
              </li>
              <li className="nav-item">
                {location.pathname === "/alianza-plus" ||
                location.pathname === "/cupones" ||
                location.pathname === "/ofertas" ||
                location.pathname === "/redenciones" ? (
                  <a
                    href="https://ntds.agency/grupoalianzacolombia/es/home"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className=" submenu">
                      <img src="/icons/eco.svg" alt="icon_redentions" />
                      Ecoturismo
                    </button>
                  </a>
                ) : (
                  ""
                )}
              </li>
              <li className="nav-item">
                <NavLink to="/">
                  <button className="btn-menu" onClick={() => logOut()}>
                    <img
                      src="/icons/logout.svg"
                      alt="image_logout"
                      height={30}
                    />
                    Cerrar Sesión
                  </button>
                </NavLink>
              </li>
            </ul>
          </div>
        ) : (
          ""
        )}
      </div>
    </nav>
  );
};

export default Navbar;
