import React from 'react'
import { NavLink } from 'react-router-dom'

const Banners = () => {

  // const [urlPrice, setUrlPrice] = useState(null)
  // const navigate = useNavigate();

  // const getPrice = async () => {
  //   const url = `${baseUrl}/api/users/getprice`;
  //   try {
  //     const response = await axios.get(url, {
  //       header : 'Content-Type Aplication/json',
  //       headers: { 'Authorization': 'Bearer '+ localStorage.getItem("token") }
  //     });
  //     if(response.msg === 'Token invalid'){
  //       localStorage.clear();
  //       navigate('/')
  //     }else{
  //       setUrlPrice(response.data);
  //     }
  //   } catch (error) {
  //     if(error.response.status === 401 ) localStorage.clear();
  //   }
  // }

  // useEffect(() => {
  //   getPrice()
  // }, [])
  
  return (
    <>
      <div id="carouselExampleIndicators" className="carousel slide carousel-fade" data-bs-ride="true" >
        <div className="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
          {/* <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button> */}
        </div>
        <div className="carousel-inner">
          {/* <div className="carousel-item active">
            <img src="/alianza/Banner-Medipiel.jpg" className="br-20 center-img" alt="Medipiel"  />
          </div> */}
          <div className="carousel-item">
            <NavLink to="/cupon/36">
              <img src="/alianza/banner_katlon.jpg" className="br-20 center-img" alt="Kalon masajes"  />
            </NavLink>  
          </div>
          <div className="carousel-item active">
            <NavLink to="/oferta/78">
              <img src="/alianza/banner1.jpg" alt="choconta glamping" className='br-20 center-img'  />
            </NavLink>  
          </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>

      <div className='centrar mt-3'>
        <div className='row'>
          <div className='col-sm-12 col-md-6 col-lg-6 col-xl-6 ' >
            <NavLink to="/cupones">
              <img src="/alianza/cupones.png" className="br-20 m-1" alt="..." />
            </NavLink>
          </div>
          <div className='col-sm-12 col-md-6 col-lg-6 col-xl-6 '>
            <NavLink to="/ofertas">
              <img src="/alianza/ofertas.jpg" className="br-20 m-1" alt="..." />
            </NavLink>
          </div>
        </div>
      </div>
    </>
  )
}

export default Banners