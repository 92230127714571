import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import useTimeOut from '../common/hook/useTimeOut';
import Banner from '../components/banner';
import Index from '../layout/Index'
import { getInfo } from '../service/getInfo';
import baseUrl from "../utils/baseUrl"


const INITIAL_STATE = {
  id_redencion: null,
  tipo: null,
  contrato: null,
  calificacion: null
};

const Redemptions = () => {

  const navigate = useNavigate();
  const [user, setUser] = useState(null)
  const [couponsRedeemeds, setCouponsRedeemeds] = useState([])
  const [OfertsRedeemeds, setOfertsRedeemeds] = useState([])
  const [addOpinion, setAddOpinion] = useState(INITIAL_STATE)
  const [succes, setSucces] = useState(false)

  const getInfoUser = async () =>{
    try {
      const response = await getInfo();
      if(response.data.msg === 'Token invalid'){
        localStorage.clear();
        navigate('/')
      }else{
        setUser(response.data.data.info.contracto.contrato)
      }
    } catch (error) {
      if(error.response.status === 401 ) localStorage.clear();
    }
  }
  const getCouponsRedeemed = async() =>{
    const url = `${baseUrl}/api/cupones/cupones-redimidos`;
    try {
      const response = await axios.post(url, {contrato: user},{
        headers: { 'Authorization': 'Bearer '+ localStorage.getItem("token") }
      });
      setCouponsRedeemeds(response.data)
    } catch (error) {
      console.log("cupones", error);
    }
  }
  const getOfertsRedeemed = async() =>{
    const url = `${baseUrl}/api/ofertas/ofertas-redimidas`;
    try {
      const response = await axios.post(url, {contrato: user},{
        headers: { 'Authorization': 'Bearer '+ localStorage.getItem("token") }
      });
      setOfertsRedeemeds(response.data)
    } catch (error) {
      console.log("ofertas",error);
    }
  }

  useEffect(() => {
    getInfoUser()
  }, [])

  useTimeOut( () => getCouponsRedeemed(), 1000)
  useTimeOut( () => getOfertsRedeemed(), 1000)

  const captureData = ( id_redencion, tipo, contrato, calificacion ) =>{
    setAddOpinion({
      id_redencion,
      tipo,
      contrato,
      calificacion
    })
  }
  
  const handleChange = (e) => {
    setAddOpinion({
      ...addOpinion,
      calificacion: e.target.value
    })
  } 
  const handleSubmit = async(e) =>{
    e.preventDefault();
    setSucces(true)
    try {
      const url = `${baseUrl}/api/calificacion`;
			const payload = { ...addOpinion };
			await axios.post(url, payload, {
        headers: { 'Authorization': 'Bearer '+ localStorage.getItem("token") }
      });
      Array.from( document.querySelectorAll('input[name="rate"]:checked'), input => input.checked = false );

      setTimeout(() => setSucces(false), 2500);
    } catch (error) {
      
    }
  }
  return (
    <Index>
      <Banner text="Redenciones" />
      {
        succes ?
        <div className="centrar">
          <div className='col-sm-12 col-md-6 col-lg-6 col-xl-6 m-3'>
            <div className="m-1 text-center alert alert-success alert-dismissible fade show" role="alert">
              <strong>Tu calificacion se ha registrado correctamente</strong> 
              <button type="button" className="btn-close"  onClick={ () => setSucces(false) } ></button>
            </div>
          </div>
        </div>
        : ''
      }
      <main className='row' style={{ margin: 0}}>
        <div className='col-sm-12 col-md-6 col-lg-6 col-xl-6'>
          <main className='contenedor-redemtions row'>  
            {
              couponsRedeemeds.length > 0 ?
                couponsRedeemeds?.map((  c, index) =>(
                  <div 
                    className="card-redemptions" 
                    key={index} 
                    data-bs-toggle="modal" 
                    data-bs-target="#exampleModal"
                    onClick={ () =>captureData(c.id_cupon, 'Cupon', user , 0)}
                  >
                    <img src={c.img} className="img" alt='img_coupon'/>
                      <div className="textBox">
                        <div className="textContent">
                          <p className="p">Cupon</p>
                          <span className="span">{ c.fecha_creacion.slice(0, 10) + ' ' +c.fecha_creacion.slice(11, 16) }</span>
                        </div>
                        <p className="p h1">{c.titulo}</p>
                        <button className='btn-redemptions'>
                          Calificar
                          <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M13.8179 4.54512L13.6275 4.27845C12.8298 3.16176 11.1702 3.16176 10.3725 4.27845L10.1821 4.54512C9.76092 5.13471 9.05384 5.45043 8.33373 5.37041L7.48471 5.27608C6.21088 5.13454 5.13454 6.21088 5.27608 7.48471L5.37041 8.33373C5.45043 9.05384 5.13471 9.76092 4.54512 10.1821L4.27845 10.3725C3.16176 11.1702 3.16176 12.8298 4.27845 13.6275L4.54512 13.8179C5.13471 14.2391 5.45043 14.9462 5.37041 15.6663L5.27608 16.5153C5.13454 17.7891 6.21088 18.8655 7.48471 18.7239L8.33373 18.6296C9.05384 18.5496 9.76092 18.8653 10.1821 19.4549L10.3725 19.7215C11.1702 20.8382 12.8298 20.8382 13.6275 19.7215L13.8179 19.4549C14.2391 18.8653 14.9462 18.5496 15.6663 18.6296L16.5153 18.7239C17.7891 18.8655 18.8655 17.7891 18.7239 16.5153L18.6296 15.6663C18.5496 14.9462 18.8653 14.2391 19.4549 13.8179L19.7215 13.6275C20.8382 12.8298 20.8382 11.1702 19.7215 10.3725L19.4549 10.1821C18.8653 9.76092 18.5496 9.05384 18.6296 8.33373L18.7239 7.48471C18.8655 6.21088 17.7891 5.13454 16.5153 5.27608L15.6663 5.37041C14.9462 5.45043 14.2391 5.13471 13.8179 4.54512Z" stroke="#323232" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                          <path d="M9 12L10.8189 13.8189V13.8189C10.9189 13.9189 11.0811 13.9189 11.1811 13.8189V13.8189L15 10" stroke="#323232" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                          </svg>
                        </button>
                      </div>
                  </div>
                ))
                : 
                <div className="card-redemptions">
                  <img src="/images/Logo-Alianza-Plus.png" className="img" alt='' style={{ objectFit: 'none'}} />
                  <div className="textBox">
                    <div className="textContent">
                      <p className="p">Cupón</p>
                    </div>
                    <p className="p h1">Aún no ha redimido ningun cupón</p>
                  </div>
                </div>
            }
          </main>
        </div>
        <div className='col-sm-12 col-md-6 col-lg-6 col-xl-6'>
          <main className='contenedor-redemtions row'>
            {
              OfertsRedeemeds.length > 0 ?
                OfertsRedeemeds?.map((  c, index) =>(
                  <div 
                    className="card-redemptions" 
                    key={index} 
                    data-bs-toggle="modal" 
                    data-bs-target="#exampleModal"
                    onClick={ () =>captureData(c.id_oferta, 'Oferta', user , 0) }
                  >
                    <img src={c.portada} className="img" alt='img_oferts'/>
                      <div className="textBox">
                        <div className="textContent">
                          <p className="p">Oferta</p>
                          <span className="span">{ c.fecha_creacion.slice(0, 10) + ' ' +c.fecha_creacion.slice(11, 16) }</span>
                        </div>
                        <p className="p">{c.titulo}</p>

                        <button className='btn-redemptions'>
                          Calificar
                          <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M13.8179 4.54512L13.6275 4.27845C12.8298 3.16176 11.1702 3.16176 10.3725 4.27845L10.1821 4.54512C9.76092 5.13471 9.05384 5.45043 8.33373 5.37041L7.48471 5.27608C6.21088 5.13454 5.13454 6.21088 5.27608 7.48471L5.37041 8.33373C5.45043 9.05384 5.13471 9.76092 4.54512 10.1821L4.27845 10.3725C3.16176 11.1702 3.16176 12.8298 4.27845 13.6275L4.54512 13.8179C5.13471 14.2391 5.45043 14.9462 5.37041 15.6663L5.27608 16.5153C5.13454 17.7891 6.21088 18.8655 7.48471 18.7239L8.33373 18.6296C9.05384 18.5496 9.76092 18.8653 10.1821 19.4549L10.3725 19.7215C11.1702 20.8382 12.8298 20.8382 13.6275 19.7215L13.8179 19.4549C14.2391 18.8653 14.9462 18.5496 15.6663 18.6296L16.5153 18.7239C17.7891 18.8655 18.8655 17.7891 18.7239 16.5153L18.6296 15.6663C18.5496 14.9462 18.8653 14.2391 19.4549 13.8179L19.7215 13.6275C20.8382 12.8298 20.8382 11.1702 19.7215 10.3725L19.4549 10.1821C18.8653 9.76092 18.5496 9.05384 18.6296 8.33373L18.7239 7.48471C18.8655 6.21088 17.7891 5.13454 16.5153 5.27608L15.6663 5.37041C14.9462 5.45043 14.2391 5.13471 13.8179 4.54512Z" stroke="#323232" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                          <path d="M9 12L10.8189 13.8189V13.8189C10.9189 13.9189 11.0811 13.9189 11.1811 13.8189V13.8189L15 10" stroke="#323232" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                          </svg>
                        </button>
                      </div>
                  </div>
                ))
              :
              <div className="card-redemptions">
                <div className="textBox">
                  <div className="textContent">
                    <p className="p">Oferta</p>
                  </div>
                  <p className="p h1">Aún no ha redimido ninguna oferta</p>
                </div>
                <img src="/images/Logo-Alianza-Plus.png" className="img" alt='' style={{ objectFit: 'none'}} />
              </div>
            }
          </main>
        </div>
      </main>
      <div className="modal fade" id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content bg-azul" style={{ borderRadius: '1.5rem'}}>
            <div className="modal-body">
              <p className='text-center'>Calificación</p> 
              <div className="centrar">
                <form onSubmit={ handleSubmit}>
                  <div className="rate">
                    <input type="radio" id="star5" name="rate" value="5" onChange={ handleChange } />
                    <label htmlFor="star5" title="Excelente"></label>
                    <input type="radio" id="star4" name="rate" value="4" onChange={ handleChange } />
                    <label htmlFor="star4" title="Muy bueno"></label>
                    <input type="radio" id="star3" name="rate" value="3" onChange={ handleChange } />
                    <label htmlFor="star3" title="Aceptable"></label>
                    <input type="radio" id="star2" name="rate" value="2" onChange={ handleChange } />
                    <label htmlFor="star2" title="Insuficiente"></label>
                    <input type="radio" id="star1" name="rate" value="1" onChange={ handleChange } />
                    <label htmlFor="star1" title="Deficiente"></label>
                  </div>
                  <div className="modal-footer">
                    <button className='btn-redemptions' data-bs-dismiss="modal" style={{ border: 'none'}}>Enviar</button>
                  </div>
                    
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Index>
  )
}

export default Redemptions