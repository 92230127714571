import React from 'react';

const Promotions = ( {urlPrice}) => {

  return (
    <div id="carouselExampleCaptions" className="carousel slide carousel-fade" data-bs-ride="carousel">
      <div className="carousel-inner">
        <div className="carousel-item active" data-bs-interval="2000">
          <a href={`${urlPrice}`} target='_blank' rel='noreferrer'>
            <img src="/promos_price/AliadosLasVegas.png" className="card-carousel" alt="Las vegas" rel="noreferrer"/>
          </a>
        </div>
        <div className="carousel-item " data-bs-interval="2000">
          <a href={`${urlPrice}`} target='_blank' rel='noreferrer'>
            <img src="/promos_price/AliadosPanama.png" className="card-carousel" alt="Aliados Panama" rel="noreferrer"/>
          </a>
        </div>
        <div className="carousel-item " data-bs-interval="2000">
          <a href={`${urlPrice}`} target='_blank' rel='noreferrer'>
            <img src="/promos_price/AliadosSantorini.png" className="card-carousel" alt="Aliados Santorini" rel="noreferrer"/>
          </a>
        </div>
        <div className="carousel-item " data-bs-interval="2000">
          <a href={`${urlPrice}`} target='_blank' rel='noreferrer'>
            <img src="/promos_price/DelMar.png" className="card-carousel" alt="Aliados Viña Del Mar" rel="noreferrer"/>
          </a>
        </div>
      </div>
      <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
      </button>
      <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
      </button>
    </div>
  )
}

export default Promotions