import axios from "axios";

const sendMessage  = async (phone, message ) =>{
  const url = `https://ws-api-node-production.up.railway.app/chat/sendmessage`;
  const resp = await axios.post(url, {"phone": phone, "message": message},
    {
      header : 'Content-Type Aplication/json',
      headers: { 'Authorization': 'Bearer '+ localStorage.getItem("token") }
    }
  )
  return resp;
}


export {
  sendMessage
}