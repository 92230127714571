import axios from "axios";
import baseUrl from "../utils/baseUrl";

const ValidateCoupon = async (id_cupon, contrato ) =>{
  const url = `${baseUrl}/api/cupones/validarcupon`;
  const resp = await axios.post(url, {"contrato": contrato, "id": parseInt(id_cupon)},
    {
      header : 'Content-Type Aplication/json',
      headers: { 'Authorization': 'Bearer '+ localStorage.getItem("token") }
    }
  )
  return resp;
}
const ValidateBid = async (id_cupon, contrato ) =>{
  const url = `${baseUrl}/api/ofertas/validaroferta`;
  const resp = await axios.post(url, {"contrato": contrato, "id": parseInt(id_cupon)},
    {
      header : 'Content-Type Aplication/json',
      headers: { 'Authorization': 'Bearer '+ localStorage.getItem("token") }
    }
  )
  return resp;
}

export {
  ValidateCoupon,
  ValidateBid
}