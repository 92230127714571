import React from 'react'

const Select_payment_gateway = ({form_pay, setform_pay, setGoSteps}) => {

  const select_pay = ( type ) => {
    setform_pay({
      ...form_pay,
      pasarela: type
    }) 
    setGoSteps(2)
  }
  
  return (
    <div className="container">
      <div className="mt-5 centrar">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 centrar">
            <a href="https://portalpagos.davivienda.com/#/comercio/6857/GRUPO%20ALIANZA%20COLOMBIA%20SAS" target="_blank" rel="noopener noreferrer" className="card_payment_pse m-1">
              <img src='/images/pasarela/pse.png' alt='pse logo' />
              <p>Pagos seguros en línea</p>
            </a>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 centrar">
            <button
              className="card_payment_mercado_pago m-1"
              onClick={ () => select_pay('mercado_pago')}
            >
              <img src='/images/pasarela/logo-mercado-pago.svg' alt='mercado_pago' />
            </button>
          </div>
        </div>
      </div>
    </div> 
  )
}

export default Select_payment_gateway