import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getInfo } from "../../service/getInfo";
import baseUrl from "../../utils/baseUrl";
import useTimeOut from "../hook/useTimeOut";
import SecondLoad from "../SecondLoad";

const DetailsPay = ({ form_pay, setform_pay, setGoSteps }) => {
  const [data_pay, setData_pay] = useState(null);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  const formatterPeso = new Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    minimumFractionDigits: 0,
  });

  const getPay = async () => {
    try {
      const url = `${baseUrl}/api/users/payments`;
      const resp = await axios.post(
        url,
        { codigo: user.validaciones.codigo },
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      );
      if (resp.data.msg === "Token invalid") {
        localStorage.clear();
        navigate("/");
      } else {
        setData_pay(resp.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getInfoUser = async () => {
    try {
      const response = await getInfo();
      if (response.data.msg === "Token invalid") {
        localStorage.clear();
        navigate("/");
      } else {
        setUser(response.data.data.info);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getInfoUser();
  }, []);

  useTimeOut(() => getPay(), 1500);

  return (
    <div className="container">
      {data_pay ? (
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="centrar">
              <table className="">
                <thead>
                  <tr>
                    <th scope="col">Fecha de pago</th>
                    <th scope="col">Pago al día</th>
                    <th scope="col">Pago total</th>
                    <th scope="col">Pagar</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td data-label="Fecha de pago">
                      {data_pay.fecha_mora || "Se encuentra al día"}
                    </td>
                    <td data-label="Pago al día">
                      <input
                        type="radio"
                        name="monto"
                        id="pago_al_dia"
                        onClick={() =>
                          setform_pay({
                            ...form_pay,
                            nombre: user.afiliado1.titular,
                            correo: user.afiliado1.email,
                            contrato: user.contracto.contrato,
                            monto: data_pay.pago_al_dia,
                          })
                        }
                      />
                      {" " + formatterPeso.format(data_pay.pago_al_dia || 0)}
                    </td>
                    <td data-label="Pago total">
                      <input
                        type="radio"
                        name="monto"
                        id="pago_total"
                        onClick={() =>
                          setform_pay({
                            ...form_pay,
                            nombre: user.afiliado1.titular,
                            correo: user.afiliado1.email,
                            contrato: user.contracto.contrato,
                            monto: data_pay.pago_total,
                          })
                        }
                      />
                      {" " + formatterPeso.format(data_pay.pago_total || 0)}
                    </td>
                    <td>
                      <form onSubmit={() => setGoSteps(1)}>
                        <div className="input-group-pay">
                          <input
                            type="number"
                            className="input-pay"
                            placeholder="Valor a pagar"
                            autoComplete="off"
                            min="1000"
                            required
                            name="monto"
                            value={form_pay.monto}
                            onChange={(e) =>
                              setform_pay({
                                ...form_pay,
                                nombre: user.afiliado1.titular,
                                correo: user.afiliado1.email,
                                contrato: user.contracto.contrato,
                                monto: e.target.value,
                              })
                            }
                          />
                          <button className="button--submit-pay">
                            Generar pago
                          </button>
                        </div>
                      </form>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="4" style={{ padding: "0px" }}></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        <SecondLoad />
      )}
    </div>
  );
};

export default DetailsPay;
