import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import baseUrl from "../../utils/baseUrl";
import axios from "axios";
import { useMercadopago } from "react-sdk-mercadopago/lib";
import useTimeOut from "../hook/useTimeOut";
import SecondLoad from "../SecondLoad";

const MakePayment = ({ form_pay }) => {
  const [url_payu, setUrl_payu] = useState(null);
  const [id_mercado, setId_mercado] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const send_payu = async () => {
    setLoading(true);
    setUrl_payu(null);
    try {
      const url = `${baseUrl}/api/payu/pagos`;
      const resp = await axios.post(url, form_pay, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      });
      if (resp.data.msg === "Token invalid") {
        localStorage.clear();
        navigate("/");
      } else {
        setUrl_payu(resp.data);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const get_mercado_pago = async () => {
    setLoading(true);
    try {
      const url = `${baseUrl}/api/mercadopago/pagos`;
      const resp = await axios.post(
        url,
        { contrato: form_pay.contrato, valor: parseInt(form_pay.monto) },
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      );
      setId_mercado(resp.data);
    } catch (error) {
      console.log(error);
    }
  };

  const mercadopago = useMercadopago.v2(
    "APP_USR-3614a58f-702f-4e86-b141-65cf43b51eed",
    {
      locale: "es-CO",
    }
  );

  const render_btn_mercado = () => {
    mercadopago.checkout({
      preference: {
        id: id_mercado,
      },
      render: {
        container: ".cho-container",
        label: "Pagar con MercadoPago",
      },
    });
    setLoading(false);
  };

  useTimeOut(() => render_btn_mercado(), 2000);

  useEffect(() => {
    if (form_pay.pasarela === "pay_u") {
      send_payu();
    } else {
      get_mercado_pago();
    }
  }, []);

  return (
    <div className="centrar">
      {loading ? <SecondLoad /> : ""}
      {url_payu ? (
        <div className="centrar mt-5">
          <a
            href={url_payu}
            target="_blank"
            rel="noopener noreferrer"
            className="m-2 btn-oferta"
          >
            Continuar con el pago
          </a>
        </div>
      ) : (
        ""
      )}

      {id_mercado ? (
        <div className="mt-5">
          <div className="cho-container" />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default MakePayment;
