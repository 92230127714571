import React, { useContext, useEffect, useState } from "react";
import baseUrl from "../../utils/baseUrl";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../../context/DataContext";
import Navbar from "../Navbar";
import ReactGA from "react-ga";

const INITIAL_USER = {
  contrato: "",
  password: "",
};

const Signin = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(INITIAL_USER);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const { setData } = useContext(DataContext);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname); //Google Analytics
    setData(null);
    const isExpired = localStorage.getItem("token");

    if (isExpired) {
      setTimeout(() => {
        navigate("/reserva");
      }, "0");
    }

    const isUser = Object.values(user).every((el) => Boolean(el));
    isUser ? setDisabled(false) : setDisabled(true);
    setError(false);
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    ReactGA.event({
      category: "Pagina de inicio",
      action: "Sistema de autenticación priority",
      label: "Notificación solcitud inicio de sesión",
      value: user,
    });

    setDisabled(true);
    setLoading(true);
    try {
      const url = `${baseUrl}/api/users/getuser`;
      const payload = { ...user };
      const response = await axios.post(url, payload, {
        header: "Content-Type Aplication/json",
      });
      setLoading(false);
      if (response.data.status === 500) {
        setError(true);
      } else {
        localStorage.setItem("token", response.data.token);
        navigate("/reserva");
      }
      setDisabled(false);
    } catch (error) {
      console.log(error);
      setError(true);
    } finally {
      setDisabled(false);
      setError(true);
      setLoading(false);
    }
  };

  return (
    <div className="container-fluid">
      <Navbar />
      <section className="centrar bg-degrade">
        <div className="container py-3">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col col-xl-4">
              <div className="card p-4 Mycard">
                <div className="centrar">
                  <img
                    src="/images/logo_aliados.png"
                    alt="Grupoa Alianza Colombia"
                    width={150}
                  />
                </div>
                <form onSubmit={handleSubmit} className="mt-4">
                  {error ? (
                    <div
                      className="m-1 text-center alert alert-danger alert-dismissible fade show"
                      role="alert"
                    >
                      <strong>¡Error!</strong> Contrato o contraseña
                      incorrectos.
                      <button
                        onClick={() => setError(false)}
                        type="button"
                        className="btn-close"
                      ></button>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="form-group">
                    <label>Contrato</label>
                    <input
                      className="form-control"
                      placeholder="Contrato de Alianza"
                      name="contrato"
                      type="text"
                      value={user.contrato.toUpperCase()}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Contraseña</label>
                    <input
                      className="form-control"
                      placeholder="Contraseña de Alianza"
                      name="password"
                      type="password"
                      value={user.password}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="centrar">
                    <button
                      className="mt-3 btn-login"
                      type="submit"
                      disabled={disabled}
                    >
                      {loading ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>{" "}
                          Cargando...
                        </>
                      ) : (
                        "Iniciar Sesión "
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Signin;
