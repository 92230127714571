import React from 'react'

const UserInfo = ({data}) => {
  const formatterPeso = new Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency: 'COP',
    minimumFractionDigits: 0
  })

  return (
    <div className='card-info mt-3'>
      <div className='card-user'>
        <div className='row'>
          <div className='col-sm-12 col-md-4 col-lg-4 col-xl-4'>
            <img className='centrar' src='/images/logo_aliados.png' alt="logo" width={150}/>
          </div>
          <div className='col-sm-12 col-md-8 col-lg-8 col-xl-8 '>
            <p className='text-dark'>Información del contrato</p>
            <p> Contrato: {data?.contracto?.contrato} </p>  
            <p> Categoría: {data?.contracto?.membresia}</p>
            <p> Valor del contrato: {formatterPeso.format(data?.contracto?.valor || 0 )}</p>
          </div>
        </div>
        <hr></hr>
        <div className='row'>
          <div className='col-sm-12 col-md-4 col-lg-4 col-xl-4'>
            <img className='' src='/images/logo_aliados.png' alt="logo" width={150} />
          </div>
          <div className='col-sm-12 col-md-8 col-lg-8 col-xl-8'>
            <p className='text-dark'>Información de la membresía</p>
            <p>
              Pago: {formatterPeso.format(data?.contracto?.valor_pago || 0)}  {' '}
              Saldo:{formatterPeso.format(data?.contracto?.saldo || 0 )} 
            </p>
            {
              data.contracto.noches ?
                <p>Disponible: {data?.contracto?.noches?.slice(0,3) || 0} noches por año</p>
              : ''
            }
            <p> 
              Beneficiarios: {data?.contracto?.beneficiarios + ' '}
              Afiliados:     {data?.contracto?.afiliados}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserInfo