import React, { useEffect, useState } from "react";
import Banner from "../components/banner";
import Promotions from "../components/promotions";
import UserInfo from "../components/userInfo";
import ReactGA from "react-ga";
import { getPrice } from "../service/getPrice";
import { getInfo } from "../service/getInfo";
import Index from "./Index";
import ChangePassword from "../components/changePassword";
import { useNavigate } from "react-router-dom";
import UserMora from "../common/UserMora";
import SecondLoad from "../common/SecondLoad";
const TRACKING_ID = "G-BJ1KD1C6WZ";

const Dashboard = () => {
  const [urlPrice, setUrlPrice] = useState(null);
  const [info, setInfo] = useState(null);
  const navigate = useNavigate();

  function capitalizar(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  const crossLogin = async () => {
    try {
      const response = await getPrice();
      if (response.msg === "Token invalid") {
        localStorage.clear();
        return navigate("/");
      } else {
        setUrlPrice(response);
      }
    } catch (error) {
      if (error.response.status === 401) localStorage.clear();
    }
  };

  const getInfoUser = async () => {
    try {
      const response = await getInfo();
      if (response.data.msg === "Token invalid") {
        localStorage.clear();
        return navigate("/");
      } else {
        if (Object.keys(response.data.data.info.afiliado1).length > 0) {
          setInfo(response.data.data.info);
        } else {
          navigate("/usuario");
        }
      }
    } catch (error) {
      console.log(error);
      // if(error.response.status === 401 ) localStorage.clear();
    }
  };

  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview(window.location.pathname); //Google Analytics

    ReactGA.event({
      category: "Pagina Dashboard",
      action: "Inicio se sesión correcto",
      label: "Notificación solicitud inicio de sesión",
    });

    getInfoUser();
    crossLogin();
  }, []);

  return (
    <>
      {info ? (
        <>
          {info.validaciones.chpwd === "1" ? (
            <ChangePassword />
          ) : (
            <Index>
              <div
                id="carouselExampleSlidesOnly"
                className="carousel slide"
                data-bs-ride="carousel"
              >
                {Object.keys(info).length > 0 ? (
                  <div className="carousel-inner">
                    <div
                      className="carousel-item active"
                      data-bs-interval="5000"
                    >
                      <Banner
                        text={capitalizar(info?.afiliado1?.titular) || ""}
                      />
                    </div>
                    {info?.afiliado2?.titular ? (
                      <div className="carousel-item" data-bs-interval="5000">
                        <Banner
                          text={capitalizar(info?.afiliado2?.titular) || ""}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
              {info.validaciones.mora === "1" ? (
                <UserMora text={info.validaciones.mmora.slice(38)} />
              ) : (
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-7 col-xl-7">
                    {urlPrice ? (
                      <Promotions urlPrice={urlPrice} />
                    ) : (
                      <SecondLoad />
                    )}
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-5 col-xl-5">
                    {Object.keys(info).length > 0 ? (
                      <UserInfo data={info} />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )}
            </Index>
          )}
        </>
      ) : (
        <Index>
          <SecondLoad />
        </Index>
      )}
    </>
  );
};

export default Dashboard;
