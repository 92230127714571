import React from 'react';
import './index.css';
import './styles/main.css'
import Main from './layout/main';
import { DataProvider } from './context/DataContext';
import RouterApp from './router/routes';
import ReactGA from 'react-ga'

const TRACKING_ID = "G-BJ1KD1C6WZ";
ReactGA.initialize(TRACKING_ID);

const App = () => {
  
  return (
    <DataProvider>
      <Main>
        <RouterApp />
      </Main>
    </DataProvider>
  )
}

export default App