import React from "react";
import { NavLink, useLocation } from "react-router-dom";

const Menu = () => {
  const location = useLocation();

  const logOut = () => {
    localStorage.clear();
  };
  return (
    <div className="menu-lateral">
      <NavLink to="/dashboard">
        <button
          className={`${
            location.pathname === "/dashboard" ? "btn-menu-active" : "btn-menu"
          }`}
        >
          <img src="/icons/Membresia.svg" alt="icon_membresia" height={30} />
          Membresía
        </button>
      </NavLink>
      <NavLink to="/reserva">
        <button
          className={`${
            location.pathname === "/reserva" ? "btn-menu-active" : "btn-menu"
          }`}
        >
          <img src="/icons/Reserva.svg" alt="icon_reservar" height={30} />
          Reservar
        </button>
      </NavLink>
      {/* <NavLink to="/alianza-plus">
        <button 
          className={`${location.pathname === '/alianza-plus' || location.pathname === '/cupones' ||  location.pathname === '/ofertas' || location.pathname.includes('/oferta/') || location.pathname.includes('/cupon/')
                ? 'btn-menu-active' : 'btn-menu'}`
            }
        >
          <img src='/images/Logo-Alianza-Plus.png' alt='icons_plus' height={30}/> {' '}
          Alianza Plus
        </button>
      </NavLink> */}
      {location.pathname === "/alianza-plus" ||
      location.pathname === "/cupones" ||
      location.pathname === "/ofertas" ||
      location.pathname === "/redenciones" ? (
        <a
          href="https://www.assistcard.com/co/b2c/grupo-alianza-colombia"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button
            className={`${
              location.pathname === "/redenciones" ? "submenu" : " submenu"
            }`}
          >
            <img src="/icons/right.svg" alt="icon_redentions" height={90} />
            <img
              src="/icons/assistCard.png"
              alt="icon_redentions"
              style={{ height: "24px", width: "48%", objectFit: "cover" }}
            />
          </button>
        </a>
      ) : (
        ""
      )}
      {location.pathname === "/alianza-plus" ||
      location.pathname === "/cupones" ||
      location.pathname === "/ofertas" ||
      location.pathname === "/redenciones" ? (
        <a
          href="https://ntds.agency/grupoalianzacolombia/es/home"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className=" submenu">
            <img src="/icons/eco.svg" alt="icon_redentions" />
            Ecoturismo
          </button>
        </a>
      ) : (
        ""
      )}
      {/* {
        location.pathname === '/alianza-plus' || location.pathname === '/cupones' || location.pathname === '/ofertas' || location.pathname === '/redenciones' ? 
          <NavLink to="/redenciones">
            <button
            className={`${location.pathname === '/redenciones' ? 'btn-menu-active' : 'btn-menu'}`}
            >
              <img src='/icons/sh4.svg' alt='icon_redentions' height={30}/>
              Redenciones
            </button>
          </NavLink>
        : ''  
      } */}
      {/* <NavLink to="/pagos">
        <button className={`${location.pathname === '/pagos' ? 'btn-menu-active' : 'btn-menu'}`}>
          <img src='/icons/card_pay.svg' alt='icon_pay' height={30}/>
          Pagos
        </button>
      </NavLink> */}
      {/* <NavLink to="/soporte">
        <button className={`${location.pathname === '/soporte' ? 'btn-menu-active' : 'btn-menu'}`}>
          <img src='/icons/Soporte.svg' alt='icon_soporte' height={30}/>
          Soporte
        </button>
      </NavLink> */}
      <NavLink to="/">
        <button className="btn-menu" onClick={() => logOut()}>
          <img src="/icons/logout.svg" alt="icon_out" height={30} />
          Cerrar Sesión
        </button>
      </NavLink>
    </div>
  );
};

export default Menu;
