import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import baseUrl from "../utils/baseUrl";
import { ValidateBid } from "../service/validateCoupon";
import { redimirOferta } from "../service/redimir";
import { sendMessage } from "../service/send";
import ReactGA from "react-ga";
import { getInfo } from "../service/getInfo";
import confetti from "canvas-confetti";
import Index from "../layout/Index";
import useTimeOut from "../common/hook/useTimeOut";
import Starts from "../common/Starts";
import SecondLoad from "../common/SecondLoad";

const Bid = () => {
  const { id } = useParams();
  const [oferta, setOferta] = useState(null);
  const [redimir, setRedimir] = useState(false);
  const [couponRedeem, setCouponRedeem] = useState(false);
  const [user, setUser] = useState("");
  const navigate = useNavigate();
  //get info offert
  const getBidById = async () => {
    try {
      const url = `${baseUrl}/api/ofertas/ofertaById/${id}`;
      const response = await axios.get(url, {
        header: "Content-Type Aplication/json",
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      });
      if (response.data.msg === "Token invalid") {
        localStorage.clear();
        navigate("/");
      } else {
        setOferta(response.data[0]);
      }
    } catch (error) {
      if (error.response.status === 401) localStorage.clear();
    }
  };

  // validate that the offer was redeemed
  const validate = async () => {
    try {
      const response = await ValidateBid(id, user.contracto.contrato);
      if (response.data === "Ya redimiste esta oferta") {
        setRedimir(true);
      }
    } catch (error) {
      // localStorage.clear();
    }
  };

  // get info user
  const getInfoUser = async () => {
    try {
      const response = await getInfo();
      if (response.data.msg === "Token invalid") {
        localStorage.clear();
        navigate("/");
      } else {
        if (Object.keys(response.data.data.info.afiliado1).length > 0) {
          setUser(response.data.data.info);
        } else {
          navigate("/usuario");
        }
      }
    } catch (error) {
      if (error.response.status === 401) localStorage.clear();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    ReactGA.event({
      category: "Pagina de oferta",
      action: "Ingreso pagina de oferta",
      label: "Notificación Ingreso pagina de oferta",
      value: `${user.afiliado1.titular} redimio el cupon ${oferta.titulo}`,
    });

    const convenio = "57" + 3137597403;
    const numAfiliado =
      "57" + user.afiliado1.telefono.includes(";")
        ? "57" + user.afiliado1.telefono.split(";", 1)
        : "57" + user.afiliado1.telefono;

    sendMessageWhatsapp(
      numAfiliado,
      `👋🏻 ${user.afiliado1.titular}. 🎉Felicitaciones🎉 
    Ya tienes tu oferta ${oferta.titulo}, en unos minutos uno de nuestros expertos en turismo se comunicara contigo para finalizar nuestro proceso de redención. 🤗`
    );

    sendMessageWhatsapp(
      convenio,
      `
    👋🏻¡Hola!, nuestro afiliado ${user.afiliado1.titular} con el contrato  ${user.contracto.contrato} esta interesado en redimir la oferta  ${oferta.titulo}, puedes ponerte contacto con nuestro afiliado al numero ${numAfiliado}.
    `
    );
    setRedimir(true);
    try {
      await redimirOferta(id, user.contracto.contrato);
      validate();
      setCouponRedeem(true);
      getBidById();

      let end = Date.now() + 3 * 1000;
      let colors = ["#003893", "#ffcf04"];

      (function frame() {
        confetti({
          particleCount: 2,
          angle: 60,
          spread: 55,
          origin: { x: 0 },
          colors: colors,
        });
        confetti({
          particleCount: 2,
          angle: 120,
          spread: 55,
          origin: { x: 1 },
          colors: colors,
        });

        if (Date.now() < end) {
          requestAnimationFrame(frame);
        }
      })();
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } catch (error) {
      alert("No se ha podido redimir la oferta, intentelo de nuevo");
    }
  };

  const sendMessageWhatsapp = async (phone, message) => {
    await sendMessage(phone, message);
  };

  useEffect(() => {
    getInfoUser();
    getBidById();
    ReactGA.pageview(window.location.pathname); //Google Analytics
  }, []);

  useTimeOut(() => validate(), 500);

  return (
    <Index>
      {oferta != null ? (
        <div className="container">
          <h3 className="text-center title-alianza-plus">{oferta.hotel}</h3>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <nav aria-label="breadcrumb centrar">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <NavLink className="text-dark" to={`/alianza-plus`}>
                      Alianza plus
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item">
                    <NavLink className="text-dark" to={`/ofertas`}>
                      Ofertas
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Redimir
                  </li>
                </ol>
              </nav>
              {oferta ? (
                <>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <div
                        id="carouselExampleIndicators"
                        className="carousel slide"
                        data-bs-ride="true"
                      >
                        <div className="carousel-indicators">
                          <button
                            type="button"
                            data-bs-target="#carouselExampleIndicators"
                            data-bs-slide-to="0"
                            className="active"
                            aria-current="true"
                            aria-label="Slide 1"
                          ></button>
                          <button
                            type="button"
                            data-bs-target="#carouselExampleIndicators"
                            data-bs-slide-to="1"
                            aria-label="Slide 2"
                          ></button>
                          <button
                            type="button"
                            data-bs-target="#carouselExampleIndicators"
                            data-bs-slide-to="2"
                            aria-label="Slide 3"
                          ></button>
                          <button
                            type="button"
                            data-bs-target="#carouselExampleIndicators"
                            data-bs-slide-to="3"
                            aria-label="Slide 4"
                          ></button>
                          <button
                            type="button"
                            data-bs-target="#carouselExampleIndicators"
                            data-bs-slide-to="4"
                            aria-label="Slide 5"
                          ></button>
                        </div>
                        <div className="carousel-inner">
                          <div className="carousel-item active">
                            <img
                              src={oferta?.img1}
                              className="d-block w-100"
                              alt="..."
                            />
                          </div>
                          <div
                            className="carousel-item"
                            data-bs-interval="2000"
                          >
                            <img
                              src={oferta?.img2}
                              className="d-block w-100"
                              alt="..."
                            />
                          </div>
                          <div
                            className="carousel-item"
                            data-bs-interval="2000"
                          >
                            <img
                              src={oferta?.img3}
                              className="d-block w-100"
                              alt="..."
                            />
                          </div>
                          <div
                            className="carousel-item"
                            data-bs-interval="2000"
                          >
                            <img
                              src={oferta?.img4}
                              className="d-block w-100"
                              alt="..."
                            />
                          </div>
                          <div
                            className="carousel-item"
                            data-bs-interval="2000"
                          >
                            <img
                              src={oferta?.img5}
                              className="d-block w-100"
                              alt="..."
                            />
                          </div>
                        </div>
                        <button
                          className="carousel-control-prev"
                          type="button"
                          data-bs-target="#carouselExampleIndicators"
                          data-bs-slide="prev"
                        >
                          <span
                            className="carousel-control-prev-icon"
                            aria-hidden="true"
                          ></span>
                          <span className="visually-hidden">Previous</span>
                        </button>
                        <button
                          className="carousel-control-next"
                          type="button"
                          data-bs-target="#carouselExampleIndicators"
                          data-bs-slide="next"
                        >
                          <span
                            className="carousel-control-next-icon"
                            aria-hidden="true"
                          ></span>
                          <span className="visually-hidden">Next</span>
                        </button>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      {couponRedeem ? (
                        <div
                          className="m-2 text-center alert alert-success alert-dismissible fade show p-3"
                          role="alert"
                        >
                          <strong>¡Felicidades!</strong> esta oferta se ha
                          redimido correctamente.
                        </div>
                      ) : (
                        ""
                      )}
                      <h1 className="title-sections text-center">
                        {oferta?.titulo}
                      </h1>
                      <Starts score={5} />
                      <p className="text-danger centrar">
                        ({Math.floor(Math.random() * (57 - 28 + 1)) + 28}{" "}
                        reviews)
                      </p>
                      <div className="row centrar">
                        <div className="col-5 text-danger">
                          <h6>Precio Regular:</h6>
                          <del className="price-tag2">
                            {oferta.precio_regular}
                          </del>
                        </div>
                        <div className="col-5">
                          <h6>Precio Afiliado:</h6>
                          <p className="price-tag">{oferta.precio_descuento}</p>
                        </div>
                      </div>
                      <div className="row centrar">
                        <div className="col-6">
                          <p className="">
                            Unidades disponibles:{" "}
                            <strong>{oferta?.cupo}</strong>
                          </p>
                        </div>
                        <div className="col-6">
                          <p className="">
                            Oferta valida hasta:{" "}
                            <strong>{oferta?.expira}</strong>
                          </p>
                        </div>
                      </div>

                      <p className="centrar">
                        <strong>Descripción</strong>
                      </p>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: oferta?.descripcion,
                        }}
                      ></p>

                      <button
                        className="mt-4 btn-oferta"
                        disabled={redimir}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        {redimir ? "Este oferta ya fue redimida" : "Redimir"}
                      </button>

                      <div
                        className="modal fade"
                        id="exampleModal"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        {user.validaciones.mora === "1" ? (
                          <div className="modal-dialog">
                            <div
                              className="modal-content"
                              style={{ borderRadius: "1rem" }}
                            >
                              <div
                                className="card-mora"
                                style={{ width: "100%" }}
                              >
                                <h3 className="text-center">
                                  {" "}
                                  Tu plan vacacional presenta mora{" "}
                                  {user?.validaciones?.mmora?.slice(38)}{" "}
                                </h3>
                                <a
                                  target="_blank"
                                  className="centrar text-white m-3"
                                  href="https://wa.link/ar2rk4"
                                  rel="noopener noreferrer"
                                  style={{ textDecoration: "underline" }}
                                >
                                  <img
                                    src="/images/icono-de-whatsapp.png"
                                    alt="img_WhatsApp"
                                    style={{ width: "20px" }}
                                  />{" "}
                                  <p>Si deseas contactar tu agente de cuenta</p>
                                </a>
                                <div className="modal-footer centrar">
                                  <a
                                    href="https://vip.grupoalianzacolombia.com/pagos"
                                    className="col-sm-12 col-md-12 col-lg-12 col-xl-12 btn-payU"
                                    style={{ border: "none" }}
                                  >
                                    <span>
                                      <img
                                        src="/icons/card_pay.svg"
                                        alt="img mercado de pago"
                                        width={38}
                                        style={{ marginRight: "10px" }}
                                      />
                                      Realiza tú Pago
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="modal-dialog">
                            <div className="modal-content">
                              <div className="modal-body text-center">
                                ¿Desea redimir esta oferta?
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-outline-danger"
                                  data-bs-dismiss="modal"
                                >
                                  No, cancelar
                                </button>
                                <button
                                  type="button"
                                  className="btn-oferta"
                                  data-bs-dismiss="modal"
                                  onClick={(e) => handleSubmit(e)}
                                >
                                  Si, redimir
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <p className="mt-5 text-center">
                    <strong>Terminos y Condiciones</strong>
                  </p>
                  <p>{oferta?.tyc}</p>
                </>
              ) : (
                <div className="centrar mt-5">
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <SecondLoad />
      )}
    </Index>
  );
};

export default Bid;
