import React from 'react'
import { NavLink } from 'react-router-dom';

const PageNotFound = () => {
  return (
    <div className='container mt-5'>
      <div className='text-center'>
        <img src="/images/404.png" style={{ maxWidth: '100%'}} alt="404.png"/>
        <div className='centrar m-5'>
          <NavLink to="/dashboard">
            <button className='btn-notFound'>Regresar al inicio</button>
          </NavLink>
        </div>
      </div>
    </div>
  )
}

export default PageNotFound;