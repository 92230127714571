import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes, useRouteError} from 'react-router-dom'
import Signin from '../components/auth/signin';
import PageNotFound from '../components/err/404';
import Dashboard from '../layout/dashboard';
import Booking from '../pages/booking';
import Alliance from '../pages/alliance';
import Support from '../pages/support';
import Coupons from '../pages/coupons';
import Coupon from '../pages/coupon';
import Bidding from '../pages/bidding';
import Bid from '../pages/bid';
import ReactGA from 'react-ga'
import Navbar from '../components/Navbar';
import AuthWrapper from '../common/AuthWrapper';
import Redemptions from '../pages/Redemptions';
import InactiveUser from '../pages/InactiveUser';
import Success from '../pages/Success';
import PlaceOrder from '../pages/PlaceOrder';

const TRACKING_ID = "G-BJ1KD1C6WZ";

const RouterApp = () => {

  function ErrorBoundary(  ) {
    const error = useRouteError();
    console.error(error);
    return <>
      <Navbar />
      <h1 className='text-center'>{error.message}</h1>;
    </>
  }

  useEffect(() => {
    ReactGA.initialize(TRACKING_ID)
  }, []);
  
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" errorElement={<ErrorBoundary />} element = {<Signin />} />
        <Route path="/respuesta-pago" errorElement={<ErrorBoundary />} element = {<Success />} />
        <Route element={<AuthWrapper />}>
          <Route path="/dashboard" errorElement={<ErrorBoundary />} element={<Dashboard />} />
        </Route>
        <Route element={<AuthWrapper />}>
          <Route path="/usuario" errorElement={<ErrorBoundary />} element={<InactiveUser />} />
        </Route>
        <Route element={<AuthWrapper />}>
          <Route path="/reserva" errorElement={<ErrorBoundary />} element={<Booking />} />
        </Route>
        <Route element={<AuthWrapper />}>
          <Route path="/alianza-plus" errorElement={<ErrorBoundary />} element={<Alliance />} />
        </Route>
        <Route element={<AuthWrapper />}>
          <Route path="/pagos" errorElement={<ErrorBoundary />} element={<PlaceOrder />} />
        </Route>
        <Route element={<AuthWrapper />}>
          <Route path="/redenciones" errorElement={<ErrorBoundary />} element={<Redemptions />} />
        </Route>
        <Route element={<AuthWrapper />}>
          <Route path="/soporte" errorElement={<ErrorBoundary />} element={<Support />} />
        </Route>
        <Route element={<AuthWrapper />}>
          <Route path="/cupones" errorElement={<ErrorBoundary />} element={<Coupons />} />
        </Route>
        <Route element={<AuthWrapper />}>
          <Route path="/cupon/:id" errorElement={<ErrorBoundary />} element={<Coupon />} />
        </Route>
        <Route element={<AuthWrapper />}>
          <Route path="/ofertas" errorElement={<ErrorBoundary />} element={<Bidding />} />
        </Route>
        <Route element={<AuthWrapper />}>
          <Route path="/oferta/:id" errorElement={<ErrorBoundary />} element={<Bid />} />
        </Route>
        <Route path='*' errorElement={<ErrorBoundary />} element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  )
}

export default RouterApp