import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom';
import { getInfo } from '../service/getInfo';
import baseUrl from '../utils/baseUrl';
import Navbar from './Navbar';

const ChangePassword = () => {
  
  let INITIAL_USER = {
    contrato: '',
    password: '',
    newpassword: ''
  };
  
  const [user, setUser] = useState(INITIAL_USER)
  const [info, setInfo] = useState('')
  const [state, setState] = useState(false)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)

  // get info user
  const getInfoUser = async () =>{
    try {
      const response = await getInfo();
      if(response.data.msg === 'Token invalid'){
        localStorage.clear();
        // <Navigate to="/" replace />
      }else{
        setInfo(response.data.data.info)
      }
    } catch (error) {
      if(error.response.status === 401 ) localStorage.clear();
    }
  }

  useEffect(() => {
    getInfoUser()
  }, [])
  
  
  const handleChange = (e) => {
		const { name, value } = e.target;
		setUser((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

  function redireccionar(){
    window.location.href = "/";
    localStorage.clear();
    <Navigate to="/" replace />
  }

  const handleSubmit =async (e) => {
    e.preventDefault();
    setLoading(true)
    setState(true)
    setDisabled(true)

    try {
      const url =`${baseUrl}/api/users/changepassw`;
      await axios.post( url, 
        {
          contrato: info.contracto.contrato,
          password: info.afiliado1.cedula,
          newpassword: user.newpassword
        }
        , {
        header : 'Content-Type Aplication/json',
        headers: { 'Authorization': 'Bearer '+ localStorage.getItem("token") }
      })
      setTimeout(() => {
        redireccionar()
      }, "1000")
    } catch (error) {
      setError(true)
    }
  }

  setTimeout(function(){
    INITIAL_USER = {
      contrato: info.contracto.contrato,
      password: info.afiliado1.cedula,
    };

  }, 1000);
  return (
    <section className='container'>
      <Navbar />
      <main className='centrar bg-degrade'>
        <div className="container py-3">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col col-xl-4">
              <div className=" card p-4 Mycard">
                <div className='centrar'>
                  <img src='/images/AlianzaPriority2.webp' alt="Grupoa Alianza Colombia" />
                </div>
                <form onSubmit={handleSubmit}>
                  {
                    state ?
                      <div className="m-1 text-center alert alert-success alert-dismissible fade show" role="alert">
                        <strong>Felicidades!</strong> Contraseña cambiada correctamente.
                        <button onClick={ () => setState(false) } type="button" className="btn-close" ></button>
                      </div>
                    : ''
                  }
                  {
                    error ?
                    <div className="m-1 text-center alert alert-danger alert-dismissible fade show" role="alert">
                        <strong>¡Ocorrio un error!</strong> intentelo de nuevo.
                        <button onClick={ () => setState(false) } type="button" className="btn-close" ></button>
                      </div>
                      : ''
                  }
                  
                  <p className='m-3 text-center'><strong>Es necesario realizar el cambio de contraseña</strong></p>
                  <div className='form-group'>
                    <label>Nueva Contraseña</label>
                    <input
                      className='form-control'
                      placeholder='Nueva Contraseña'
                      name='newpassword'
                      type='password'
                      value={user.newpassword}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  
                  <div className='centrar'>
                    <button className='mt-3 btn-login' type='submit'
                      disabled={disabled}
                    >
                      {loading ? (
                        <>
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          {' '}Cargando...
                        </>
                      ) : (
                      'Cambiar contraseña'
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    </section>
  )
}

export default ChangePassword