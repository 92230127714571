import React, { useEffect, useState } from "react";
import axios from "axios";
import baseUrl from "../utils/baseUrl";
import FeaturedCoupons from "../components/cupons/FeaturedCoupons";
import HighlightedOffers from "../components/offers/HighlightedOffers";
import ReactGA from "react-ga";
import Banners from "../components/Banners";
import { useNavigate } from "react-router-dom";
import Index from "../layout/Index";
import SecondLoad from "../common/SecondLoad";

const Alliance = () => {
  const [cuponesDestacados, setCuponesDestacados] = useState(null);
  const [ofertasDestacadas, setOfertasDestacadas] = useState(null);
  const navigate = useNavigate();

  const GetCupones = async () => {
    try {
      const url = `${baseUrl}/api/cupones/destacados`;
      const response = await axios.get(url, {
        header: "Content-Type Aplication/json",
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      });
      if (response.data.msg === "Token invalid") {
        localStorage.clear();
        navigate("/");
      } else {
        setCuponesDestacados(response.data);
      }
    } catch (error) {
      if (error.response.status === 401) localStorage.clear();
      console.log(error);
    }
  };

  const GetOfertasDestacadas = async () => {
    try {
      const url = `${baseUrl}/api/ofertas/destacados`;
      const response = await axios.get(url, {
        header: "Content-Type Aplication/json",
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      });
      if (response.data.msg === "Token invalid") {
        localStorage.clear();
        navigate("/");
      } else {
        setOfertasDestacadas(response.data);
      }
    } catch (error) {
      if (error.response.status === 401) localStorage.clear();
    }
  };
  useEffect(() => {
    ReactGA.pageview(window.location.pathname); //Google Analytics

    ReactGA.event({
      category: "Pagina de alianza plus",
      action: "Pagina de alianza plus",
      label: "Notificación Pagina de alianza plus",
    });
    GetCupones();
    GetOfertasDestacadas();
  }, []);

  return (
    <Index>
      <div className="row">
        <Banners />
        <h1 className="text-center title-alianza-plus m-4">
          Cupones destacados
        </h1>
        {cuponesDestacados !== null ? (
          <FeaturedCoupons cupon={cuponesDestacados} />
        ) : (
          <SecondLoad />
        )}
        <h1 className="text-center title-alianza-plus m-4">
          Ofertas Destacadas
        </h1>
        {ofertasDestacadas ? (
          <HighlightedOffers ofertasDestacadas={ofertasDestacadas} />
        ) : (
          <SecondLoad />
        )}
      </div>
    </Index>
  );
};

export default Alliance;
