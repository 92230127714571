import axios from "axios";
import baseUrl from "../utils/baseUrl";

const redimirCoupon = async (id_cupon, contrato ) =>{
  const url = `${baseUrl}/api/cupones/redimircupon`;
  const resp = await axios.post(url, {"contrato": contrato, "id": parseInt(id_cupon)},
  {
    header : 'Content-Type Aplication/json',
    headers: { 'Authorization': 'Bearer '+ localStorage.getItem("token") }
  }
    
  )
  return resp;
}

const redimirOferta = async (id_cupon, contrato ) =>{
  const url = `${baseUrl}/api/ofertas/redimiroferta`;
  const resp = await axios.post(url, {"contrato": contrato, "id": parseInt(id_cupon)},
  {
    header : 'Content-Type Aplication/json',
    headers: { 'Authorization': 'Bearer '+ localStorage.getItem("token") }
  }
  )
  return resp;
}

const postConvenio = async(id) =>{
  const url = `${baseUrl}/api/convenios/convenioById/${id}`;
  const resp = await axios.post(url,
    {
      header : 'Content-Type Aplication/json',
      headers: { 'Authorization': 'Bearer '+ localStorage.getItem("token") }
    }
  )
  return resp;
}

export {
  redimirCoupon,
  redimirOferta,
  postConvenio
}