import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import baseUrl from "../utils/baseUrl";
import axios from "axios";
import ReactGA from "react-ga";
import Index from "../layout/Index";
import SecondLoad from "../common/SecondLoad";

const Coupons = () => {
  const [cuponesDestacados, setCuponesDestacados] = useState(null);
  const [pagination, setPagination] = useState(0);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const GetCupones = async () => {
    try {
      const url = `${baseUrl}/api/cupones`;
      const response = await axios.get(url, {
        header: "Content-Type Aplication/json",
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      });
      if (response.data.msg === "Token invalid") {
        localStorage.clear();
        navigate("/");
      } else {
        setCuponesDestacados(response.data);
      }
    } catch (error) {
      if (error.response.status === 401) localStorage.clear();
    }
  };

  useEffect(() => {
    ReactGA.pageview("/cupones"); //Google Analytics
    ReactGA.event({
      category: "Pagina de cupones",
      action: "Ingreso pagina de cupones",
      label: "Notificación Ingreso pagina de cupones",
    });
    GetCupones();
  }, []);

  const filterCoupons = () => {
    if (search.length === 0)
      return cuponesDestacados.slice(pagination, pagination + 8);
    // Busqueda activa
    const filtered = cuponesDestacados.filter(
      (cupon) =>
        cupon.titulo.toLowerCase().includes(search.toLowerCase()) ||
        cupon.ciudad.toLowerCase().includes(search.toLowerCase()) ||
        cupon.descuento.toLowerCase().includes(search.toLowerCase()) ||
        cupon.tyc.toLowerCase().includes(search.toLowerCase()) ||
        cupon.nombre_convenio.toLowerCase().includes(search.toLowerCase())
    );
    return filtered.slice(pagination, pagination + 8);
  };

  const next = () => {
    if (pagination + 8 < cuponesDestacados.length)
      setPagination(pagination + 8);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const back = () => {
    if (pagination > 0) setPagination(pagination - 8);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const onSearchChange = ({ target }) => {
    setPagination(0);
    setSearch(target.value);
  };

  return (
    <Index>
      <div className="container">
        <div className="row">
          {cuponesDestacados ? (
            <>
              <div className="containerCupon">
                <div className="coupon-card">
                  <img
                    src="/images/Logo-Alianza-Plus.png"
                    className=""
                    alt="logo_gac"
                  />
                  <h1 className="title-alianza-plus" style={{ color: "white" }}>
                    Cupones
                  </h1>

                  <div className="circle1"></div>
                  <div className="circle2"></div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <nav aria-label="centrar breadcrumb">
                    <ol className="breadcrumb text-center">
                      <li className="breadcrumb-item mt-1">
                        <NavLink className="" to={`/alianza-plus`}>
                          Alianza plus
                        </NavLink>
                      </li>
                      <li
                        className="breadcrumb-item active mt-1"
                        aria-current="page"
                      >
                        Cupones
                      </li>
                    </ol>
                  </nav>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <div className="form">
                    <label>
                      <input
                        className="input"
                        type="text"
                        required=""
                        placeholder="Buscar..."
                        id="search"
                        value={search}
                        onChange={onSearchChange}
                      />
                      <div className="fancy-bg"></div>
                      <div className="search">
                        <svg
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          className="r-14j79pv r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-4wgw6l r-f727ji r-bnwqim r-1plcrui r-lrvibr"
                        >
                          <g>
                            <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
                          </g>
                        </svg>
                      </div>
                      <button
                        className="close-btn"
                        type="reset"
                        onClick={() => setSearch("")}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"></path>
                        </svg>
                      </button>
                    </label>
                  </div>
                </div>
              </div>
              {filterCoupons()?.map((cupon) => (
                <div className="col-lg-3 col-md-3 col-sm-3" key={cupon.id}>
                  <NavLink to={`/cupon/${cupon.id}`}>
                    <div
                      className="card cardCupon cardOfertas"
                      style={{ margin: "20px 0rem" }}
                    >
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <div className="example2__ribbon example2__ribbon--tr">
                            <span className="example2__title">Ver cupón</span>
                          </div>
                          <img
                            src={cupon.img}
                            className="card-img-top"
                            alt="..."
                          />
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <div className="card-body ">
                            <p className="text-center">{cupon.titulo}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                </div>
              ))}
              <div className="row centrar">
                <div className="col-3">
                  <button
                    disabled={pagination > 0 ? false : true}
                    onClick={back}
                    className="mt-4 button-back learn-more"
                  >
                    <span className="circle" aria-hidden="true">
                      <span className="icon arrow"></span>
                    </span>
                    <span className="button-back-text">Anterior</span>
                  </button>
                </div>
                <div className="col-3">
                  <button
                    disabled={
                      pagination + 8 < cuponesDestacados.length ? false : true
                    }
                    onClick={next}
                    className="mt-4 button learn-more"
                  >
                    <span className="circle" aria-hidden="true">
                      <span className="icon arrow"></span>
                    </span>
                    <span className="button-text">Siguiente</span>
                  </button>
                </div>
              </div>
            </>
          ) : (
            <SecondLoad />
          )}
        </div>
      </div>
    </Index>
  );
};

export default Coupons;
