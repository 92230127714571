import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { postConvenio, redimirCoupon } from "../service/redimir";
import { sendMessage } from "../service/send";
import { ValidateCoupon } from "../service/validateCoupon";
import baseUrl from "../utils/baseUrl";
import ReactGA from "react-ga";
import { getInfo } from "../service/getInfo";
import confetti from "canvas-confetti";
import Index from "../layout/Index";
import useTimeOut from "../common/hook/useTimeOut";
import SecondLoad from "../common/SecondLoad";

const Coupon = () => {
  const { id } = useParams();
  const [cupon, setCupon] = useState(null);
  const [redimir, setRedimir] = useState(false);
  const [couponRedeem, setCouponRedeem] = useState(false);
  const [user, setUser] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    getInfoUser();
    GetCuponByID();
    ReactGA.pageview(window.location.pathname); //Google Analytics
  }, []);

  // /get info coupon
  const GetCuponByID = async () => {
    try {
      const url = `${baseUrl}/api/cupones/cuponById/${id}`;
      const response = await axios.get(url, {
        header: "Content-Type Aplication/json",
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      });
      if (response.data.msg === "Token invalid") {
        localStorage.clear();
        navigate("/");
      } else {
        setCupon(response.data[0]);
      }
    } catch (error) {
      if (error.response.status === 401) localStorage.clear();
    }
  };
  // validate that the coupon was redeemed
  const validate = async () => {
    try {
      const response = await ValidateCoupon(id, user.contracto.contrato);
      if (response.data === true) setRedimir(true);
    } catch (error) {
      // localStorage.clear();
    }
  };
  // get info user
  const getInfoUser = async () => {
    try {
      const response = await getInfo();
      if (response.data.msg === "Token invalid") {
        localStorage.clear();
        navigate("/");
      } else {
        if (Object.keys(response.data.data.info.afiliado1).length > 0) {
          setUser(response.data.data.info);
        } else {
          navigate("/usuario");
        }
      }
    } catch (error) {
      if (error.response.status === 401) localStorage.clear();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    ReactGA.event({
      category: "Pagina de cupones",
      action: "Ingreso pagina de cupones",
      label: "Notificación Ingreso pagina de cupones",
      value: `${user.afiliado1.titular} redimio el cupon ${cupon.titulo}`,
    });

    const numConvenio = await postConvenio(cupon.id_convenio);
    const convenio = numConvenio.data[0].contacto_convenio;
    const numAfiliado = user.afiliado1.telefono.includes(";")
      ? "57" + user.afiliado1.telefono.split(";", 1)
      : "57" + user.afiliado1.telefono;

    sendMessageWhatsapp(
      numAfiliado,
      `👋🏻¡Hola!, ${user.afiliado1.titular}. 🎉Felicitaciones🎉 
    Ya tienes tu cupón  ${cupon.titulo}, en unos minutos el convenio aliado se comunicara contigo para finalizar nuestro proceso de redención. 🤗`
    );

    sendMessageWhatsapp(
      convenio,
      `
    👋🏻¡Hola!, ${numConvenio.data[0].nombre_convenio}
      nuestro afiliado ${user.afiliado1.titular} ha redimido el cupón ${cupon.titulo}, puedes ponerte contacto con nuestro afiliado al numero ${numAfiliado}.
    `
    );
    setRedimir(true);
    try {
      await redimirCoupon(id, user.contracto.contrato);
      validate();
      setCouponRedeem(true);

      let end = Date.now() + 3 * 1000;
      let colors = ["#003893", "#ffcf04"];

      (function frame() {
        confetti({
          particleCount: 2,
          angle: 60,
          spread: 55,
          origin: { x: 0 },
          colors: colors,
        });
        confetti({
          particleCount: 2,
          angle: 120,
          spread: 55,
          origin: { x: 1 },
          colors: colors,
        });

        if (Date.now() < end) {
          requestAnimationFrame(frame);
        }
      })();
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      GetCuponByID();
    } catch (error) {
      alert("No se ha podido redimir el cupón, intentelo de nuevo");
    }
  };

  const sendMessageWhatsapp = async (phone, message) => {
    await sendMessage(phone, message);
  };

  useTimeOut(() => validate(), 500);

  return (
    <Index>
      {cupon != null ? (
        <div className="container">
          <h2 className="text-center title-alianza-plus">
            {cupon?.nombre_convenio}
          </h2>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <NavLink className="text-dark" to={`/alianza-plus`}>
                      Alianza plus
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item">
                    <NavLink className="text-dark" to={`/cupones`}>
                      Cupones
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Redimir
                  </li>
                </ol>
              </nav>
              <div className="row">
                {cupon ? (
                  <>
                    <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5">
                      <img
                        src={cupon?.img}
                        className="card-img-top w-100"
                        alt="..."
                      />
                    </div>
                    <div className="col-sm-12 col-md-7 col-lg-7 col-xl-7">
                      {couponRedeem ? (
                        <div
                          className="m-1 text-center alert alert-success alert-dismissible fade show "
                          role="alert"
                        >
                          <strong>¡Felicidades!</strong> este cupón se ha
                          redimido correctamente.
                        </div>
                      ) : (
                        ""
                      )}
                      <h2>{cupon.titulo}</h2>
                      <p className="">Unidades disponibles {cupon?.redimir}</p>
                      <p
                        dangerouslySetInnerHTML={{ __html: cupon?.descuento }}
                      ></p>
                      <button
                        className="mt-2 btn-oferta"
                        disabled={redimir}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        {redimir ? "Este cupón ya fue redimido" : "Redimir"}
                      </button>
                    </div>

                    <div
                      className="modal fade"
                      id="exampleModal"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      {user.validaciones.mora === "1" ? (
                        <div className="modal-dialog">
                          <div
                            className="modal-content"
                            style={{ borderRadius: "1rem" }}
                          >
                            <div
                              className="card-mora"
                              style={{ width: "100%" }}
                            >
                              <h3 className="text-center">
                                {" "}
                                Tu plan vacacional presenta mora{" "}
                                {user?.validaciones?.mmora?.slice(38)}{" "}
                              </h3>
                              <a
                                target="_blank"
                                className="centrar text-white m-3"
                                href="https://wa.link/ar2rk4"
                                rel="noopener noreferrer"
                                style={{ textDecoration: "underline" }}
                              >
                                <img
                                  src="/images/icono-de-whatsapp.png"
                                  alt="img_WhatsApp"
                                  style={{ width: "20px" }}
                                />{" "}
                                <p>Si deseas contactar tu agente de cuenta</p>
                              </a>
                              <div className="modal-footer centrar">
                                <a
                                  href="https://vip.grupoalianzacolombia.com/pagos"
                                  className="col-sm-12 col-md-12 col-lg-12 col-xl-12 btn-payU"
                                  style={{ border: "none" }}
                                >
                                  <span>
                                    <img
                                      src="/icons/card_pay.svg"
                                      alt="img mercado de pago"
                                      width={38}
                                      style={{ marginRight: "10px" }}
                                    />
                                    Realiza tú Pago
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-body text-center">
                              ¿Desea redimir esta cupón?
                            </div>
                            <div className="modal-footer row">
                              <button
                                type="button"
                                className="col-5 btn btn-outline-danger"
                                data-bs-dismiss="modal"
                              >
                                No, cancelar
                              </button>
                              <button
                                type="button"
                                className="btn-oferta col-6"
                                data-bs-dismiss="modal"
                                onClick={(e) => handleSubmit(e)}
                              >
                                Si, redimir
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <div className="centrar">
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </div>
                )}
              </div>
              <p className="mt-5 text-center">
                <strong>Terminos y Condiciones</strong>
              </p>
              <p>{cupon?.tyc}</p>
            </div>
          </div>
        </div>
      ) : (
        <SecondLoad />
      )}
    </Index>
  );
};

export default Coupon;
